import React, { useContext } from 'react'
import { BaseContext } from 'shared/context/BaseContext'
import { EffectFlip, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ContentDisplayMode } from 'utils/contentDisplayMode'
import { ContentProps } from './definitions'
import { SCContainer } from './styles'

interface ModesProps {
  sections: ((props: ContentProps) => React.ReactNode)[]
}

const SwipeMode: React.FC<ModesProps> = ({ sections }: ModesProps): JSX.Element => {
  const { setSwiper } = useContext(BaseContext)

  return (
    <Swiper
      modules={[EffectFlip, Navigation]}
      onAfterInit={(inst: any) => {
        const update = setInterval(() => inst.update(), 250)
        setTimeout(() => clearInterval(update), 1000)
      }}
      onInit={inst => {
        setSwiper(inst)
      }}
      autoHeight
      allowTouchMove={false}
      effect="flip"
      loop
      preventClicks={false}
    >
      {sections.map((toRender, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index}>{toRender({})}</SwiperSlide>
      ))}
    </Swiper>
  )
}

const ScrollMode: React.FC<ModesProps> = ({ sections }: ModesProps): JSX.Element => (
  <div>
    {sections.map((toRender, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <SCContainer key={index}>{toRender({ withNavigation: false })}</SCContainer>
    ))}
  </div>
)

export const renderContent = (
  sections: ModesProps['sections'],
  contentMode: ContentDisplayMode = ContentDisplayMode.Scroll
): React.ReactNode => {
  let toRender = null

  switch (contentMode) {
    case ContentDisplayMode.Scroll:
      toRender = <ScrollMode sections={sections} />
      break
    case ContentDisplayMode.Swipe:
    default:
      toRender = <SwipeMode sections={sections} />
      break
  }
  return toRender
}
