/* eslint-disable jsx-a11y/anchor-is-valid */
import { PremiumUser } from 'models/premium-user'
import React, { useContext } from 'react'
import { BaseContext } from 'shared/context/BaseContext'
import { formatPeruvianPhone } from 'utils/format'
import { TemplateSettings } from 'utils/template'
import { ContentProps } from '../definitions'
import SectionNavigation from '../SectionNavigation'
import { SCContainer, SCContactList, SCDetail, SCDetailCard, SCCardTitle, SCDescription } from './styles'

interface ContactProps extends ContentProps {
  noCardBorder?: boolean
  title?: string
  contentId?: string
  contact: PremiumUser['contact']
}

const getResponsiveColumn = (len: number): string => {
  if (len === 1) {
    return 'col-md-12'
  }
  if (len === 2) {
    return 'col-md-6'
  }
  return 'col-md-4'
}

const printContent = (
  lenght: number,
  icon: string,
  title: string,
  description: React.ReactNode,
  noBorder: boolean,
  settings?: TemplateSettings
): React.ReactNode => {
  return (
    <SCDetail className={`col-12 ${getResponsiveColumn(lenght)} pt-2`}>
      <SCDetailCard noBorder={noBorder} className="card">
        <div className="card-body">
          <SCCardTitle palette={settings?.palette}>
            <i className={icon} />
            <span className="font-weight-semibold">{title}</span>
          </SCCardTitle>
          {description}
        </div>
      </SCDetailCard>
    </SCDetail>
  )
}

const Contact: React.FC<ContactProps> = ({
  noCardBorder = false,
  title = 'Mi Contacto',
  contentId,
  contact: { email, addresses, phoneNumbers },
  withNavigation,
}: ContactProps) => {
  const { settings } = useContext(BaseContext)

  let rowsLength = 1
  if (phoneNumbers && phoneNumbers.length !== 0) {
    rowsLength += 1
  }
  if (addresses && addresses.length !== 0) {
    rowsLength += 1
  }

  return (
    <SCContainer id={contentId}>
      <SectionNavigation title={title} withNavigation={withNavigation} palette={settings?.palette} />
      <SCContactList className="row">
        {email &&
          printContent(
            rowsLength,
            'far fa-envelope',
            'Email',
            <SCDescription className="text-muted">
              <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
                {email}
              </a>
            </SCDescription>,
            noCardBorder,
            settings
          )}

        {phoneNumbers &&
          phoneNumbers.length !== 0 &&
          printContent(
            rowsLength,
            'fas fa-phone',
            'Teléfonos',
            <SCDescription>
              {phoneNumbers.map(phone => (
                <p key={phone} className="text-muted">
                  <a target="_blank" rel="noreferrer" href={`tel:${phone}`}>
                    {formatPeruvianPhone(phone)}
                  </a>
                </p>
              ))}
            </SCDescription>,
            noCardBorder,
            settings
          )}

        {addresses &&
          addresses.length !== 0 &&
          printContent(
            rowsLength,
            'fas fa-map-marker-alt',
            'Direcciones',
            <SCDescription>
              {addresses.map(address => (
                <p key={address.id} className="text-muted">
                  {address.link ? (
                    <a target="_blank" rel="noreferrer" href={address.link}>
                      {address.description}
                    </a>
                  ) : (
                    address.description
                  )}
                </p>
              ))}
            </SCDescription>,
            noCardBorder,
            settings
          )}
      </SCContactList>
    </SCContainer>
  )
}
export default Contact
