import styled from '@emotion/styled'
import { TemplateSettings } from 'utils/template'

export const SCContainer = styled.div`
  margin-bottom: 24px;
`

export const SCServicesList = styled.div`
  text-align: center;
`

export const SCDetail = styled.div<TemplateSettings>`
  margin-bottom: 12px;

  & .card {
    border-radius: 18px;
    height: 100%;
  }

  & i {
    font-size: 20px;
    margin-bottom: 12px;
    color: ${({ palette }) => palette?.secondary?.normal || 'inherit'};
  }

  & .description {
    font-size: 16px;

    & ul {
      text-align: left;
      list-style: initial;
      & li {
        & p {
          margin: 0;
        }
      }
    }
  }
`
