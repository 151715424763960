import React from 'react'
import LayoutContainer from 'components/templates/premium/t/Layout'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'
import AvatarImg from 'assets/images/premium/t-pink/avatar.png'
import { BusinessProfileType, PremiumTemplates, SocialMedia } from 'utils/constants'
import { toContentfulRaw } from 'utils/format'

const defaultData: PremiumUserArgs = {
  isDemo: true,
  id: '1',
  name: 'Amy',
  firstLastName: 'Saénz',
  nameToDisplay: 'Amy Saénz',
  avatarUrl: {
    file: {
      url: AvatarImg,
    },
  },
  businessProfileType: BusinessProfileType.Personal,
  template: PremiumTemplates.TStyle_Pink,
  metadata: {
    pageTitle: 'Professional TPink',
  },
  jobs: [
    {
      id: '1',
      title: 'Cantante y Compositora',
    },
  ],
  enterprise: {
    id: '1',
    name: 'Wong',
  },
  contact: {
    id: '1',
    phoneNumbers: ['+51901422305'],
    email: 'hola@playu.pe',
    addresses: [
      {
        id: '1',
        description: {
          description: 'Lima, Peru',
        },
      },
    ],
    socialMedia: [
      {
        order: 1,
        id: '1',
        link: 'https://www.facebook.com/playu.shop',
        description: {
          description: '@amy.saenz',
        },
        type: SocialMedia.Facebook,
      },
      {
        order: 2,
        id: '2',
        link: 'https://instagram.com/playu.shop',
        description: {
          description: '@amy.saenz',
        },
        type: SocialMedia.Instagram,
      },
      {
        order: 3,
        id: '3',
        skip: true,
        link: '#',
        description: {
          description: '@amy.saenz',
        },
        type: SocialMedia.Twitter,
      },
      {
        order: 4,
        id: '4',
        link: 'https://www.tiktok.com/@playu.shop',
        description: {
          description: '@amy.saenz',
        },
        type: SocialMedia.Tiktok,
      },
      {
        order: 5,
        id: '5',
        link: 'https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU.',
        description: {
          description: '+(51) 901 422 305',
        },
        type: SocialMedia.Whatsapp,
      },
      {
        order: 6,
        id: '6',
        link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
        description: {
          description: '@amy.saenz',
        },
        type: SocialMedia.Youtube,
      },
      {
        order: 7,
        id: '7',
        link: '#',
        description: {
          description: '@amy.saenz',
        },
        type: SocialMedia.Linkedin,
      },
    ],
  },
  services: [
    {
      id: '1',
      icon: 'far fa-lightbulb',
      title: 'Creative Designs',
      description: {
        raw: toContentfulRaw(
          'Web design is what creates the over look and feel when you’re using website. It’s the...'
        ),
      },
    },
    {
      id: '2',
      icon: 'far fa-window-maximize',
      title: 'Web development',
      description: {
        raw: toContentfulRaw(
          'Web design is what creates the over look and feel when you’re using website. It’s the...'
        ),
      },
    },
    {
      id: '3',
      icon: 'fas fa-award',
      title: 'Brand Identity',
      description: {
        raw: toContentfulRaw(
          'Web design is what creates the over look and feel when you’re using website. It’s the...'
        ),
      },
    },
  ],
}

const PageComponent: React.FC = () => (
  <LayoutContainer user={new PremiumUser(defaultData)} template={PremiumTemplates.TStyle_Pink} />
)

export default PageComponent
