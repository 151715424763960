/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PremiumUser } from 'models/premium-user'
import React, { useContext } from 'react'
import { BaseContext } from 'shared/context/BaseContext'
import { toHtmlFormat } from 'utils/format'
import { ContentProps } from '../definitions'
import SectionNavigation from '../SectionNavigation'
import { SCContainer, SCServicesList, SCDetail } from './styles'

interface ServicesProps extends ContentProps {
  services: PremiumUser['services']
  withNavigation?: boolean
  title: string
  contentId?: string
}

const getResponsiveColumn = (len: number): string => {
  if (len === 1) {
    return 'col-md-12'
  }
  if (len === 2) {
    return 'col-md-6'
  }
  return 'col-md-4'
}

const Service: React.FC<ServicesProps> = ({
  title = 'Mis Servicios',
  contentId,
  services,
  withNavigation,
}: ServicesProps) => {
  const { settings } = useContext(BaseContext)
  return (
    <SCContainer id={contentId}>
      <SectionNavigation title={title} withNavigation={withNavigation} palette={settings?.palette} />
      <SCServicesList className="row">
        {services.map(el => (
          <SCDetail
            palette={settings?.palette}
            key={el.id}
            className={`col-12 ${getResponsiveColumn(services.length)} pt-2`}
          >
            <div className="card">
              <div className="card-body">
                <div className="service-icon rounded">
                  <i className={el.icon} />
                </div>
                <h5 className="font-weight-semibold pt-2">{el.title}</h5>
                {el.description && (
                  <div
                    dangerouslySetInnerHTML={{ __html: toHtmlFormat(el.description) }}
                    className="text-muted description"
                  />
                )}
              </div>
            </div>
          </SCDetail>
        ))}
      </SCServicesList>
    </SCContainer>
  )
}
export default Service
