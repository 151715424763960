import styled from '@emotion/styled'
import { PaletteColors } from 'assets/theme'
import { TemplateSettings } from 'utils/template'

export const SCContainer = styled.div`
  margin-bottom: 24px;
`

export const SCContactList = styled.div`
  text-align: center;
`

export const SCDetail = styled.div`
  margin-bottom: 12px;
`

interface SCDetailCardProps {
  noBorder: boolean
}

export const SCDetailCard = styled.div<SCDetailCardProps>`
  border-radius: 18px;
  height: 100%;
  ${({ noBorder }) => (noBorder ? 'border: none' : '')};

  & .card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`

export const SCCardTitle = styled.div<TemplateSettings>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${PaletteColors.white};
  background: ${({ palette }) => palette?.secondary?.normal || 'inherit'};
  padding: 0.75rem 1rem;
  border-top-right-radius: 18px !important;
  border-top-left-radius: 18px !important;

  & i {
    font-size: 20px;
    color: ${PaletteColors.white};
    margin-right: 10px;
  }

  & h5 {
    margin: 0;
    font-size: 16px;
  }
`

export const SCDescription = styled.div`
  font-size: 16px;
  margin: 0;
  padding: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  min-height: 66px;

  & p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    color: inherit;
  }
`
